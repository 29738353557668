<template>
  <div>
    <van-nav-bar
        title="商户详情"
        left-text="返回"
        left-arrow
        @click-left="onClickLeft"
    >
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>

    <van-field
        v-model="accountUser.nickName"
        label="商户名"
        readonly
        name="pattern"
        placeholder="商户名"
        right-icon="edit"
        @click-right-icon="editUserInfo('商户名', accountUser.nickName)"
    />
    <van-field name="uploader" label="头像">
      <template #input>
        <van-uploader v-model="fileList" :max-count="1"/>
      </template>
    </van-field>
    <van-field
        v-model="accountUser.phone"
        label="手机号码"
        name="validator"
        placeholder="手机号码"
        readonly
        right-icon="edit"
        @click-right-icon="editUserInfo('手机号码', accountUser.phone)"
    />

    <van-field
        v-model="accountUser.address"
        readonly
        label="地址"
        placeholder="请选择所在地区"
        right-icon="edit"
        @click-right-icon="editUserAddress"
    />
    <van-field
        v-model="accountUser.coordinate"
        readonly
        label="坐标"
        placeholder="请选择所在地区"
        right-icon="edit"
        @click-right-icon="editUserCoordinate"
    />
    <van-cell title="地图地址" :label="mapInfo.address" icon="award-o" />
    <van-cell title="坐标" :label="coordinate" icon="location-o" >
      <template #right-icon>
        <van-button type="primary" size="small" @click="onCoordinateSubmit">保存坐标</van-button>
      </template>
    </van-cell>
    <van-popup v-model="selectAddressPopupShow" round position="bottom">
      <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          :field-names="fieldNames"
          @close="selectAddressPopupShow = false"
          @finish="onFinish"
          @change="onAreaChange"
      />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit" @click="onAddressSubmit">提交</van-button>
      </div>
    </van-popup>
    <!--    <van-field-->
    <!--        v-model="accountUser.detailedAddress"-->
    <!--        label="详细地址"-->
    <!--        name="pattern"-->
    <!--        placeholder="请输入详细地址"-->
    <!--        readonly-->
    <!--        right-icon="edit  "-->
    <!--        @click-right-icon="editUserInfo('详细地址', accountUser.detailedAddress)"-->
    <!--    />-->

    <van-popup v-model="userInfoEditPopupShow" :style="{ width: '90%' }">
      <van-field v-model="editItem.value"
                 :label="editItem.key"
                 placeholder="请输入修改值"
      />
      <div style="margin: 16px;">
        <van-button block type="info" native-type="submit" @click="onSubmit">修改</van-button>
      </div>
    </van-popup>


    <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      近期销量
    </van-divider>

    <van-row style="background-color: #fff;">
      <van-col span="4" offset="20" style="text-align: right">
        <van-button hairline size="small" color="#7232dd" plain style="margin-top: 3px; margin-right: 3px;" @click="toUserOrder()">详情
        </van-button>
      </van-col>
    </van-row>
    <van-row class="user-links">
      <van-col span="6" @click="toUserOrder(1)">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfDay }}</span>
        本日
      </van-col>
      <van-col span="6" @click="toUserOrder(2)">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfWeek }}</span>
        本周
      </van-col>
      <van-col span="6" @click="toUserOrder(3)">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfMonth }}</span>
        本月
      </van-col>
      <van-col span="6" @click="toUserOrder(4)">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amountOfYear }}</span>
        今年
      </van-col>
    </van-row>
    <van-row class="user-links">
      <van-col span="24" @click="toUserOrder()">
        <span style="
        display: block;
        margin-bottom: 6px;
        color: #ee0a24;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;">{{ analysis.amount }}</span>
        总销量
      </van-col>
    </van-row>
    <van-divider
        :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      所属价格组
    </van-divider>

    <div class="van-share-sheet__options">
      <div role="button" tabindex="0" class="van-share-sheet__option" @click="toUserPrice">
        <img src="https://img01.yzcdn.cn/vant/share-sheet-qrcode.png" class="van-share-sheet__icon">
        <span class="van-share-sheet__name">已买价格表</span>
      </div>
<!--      <div role="button" tabindex="0" class="van-share-sheet__option">-->
<!--        <img src="https://img01.yzcdn.cn/vant/share-sheet-weibo.png" class="van-share-sheet__icon">-->
<!--        <span class="van-share-sheet__name">微博</span>-->
<!--      </div>-->
<!--      <div role="button" tabindex="0" class="van-share-sheet__option">-->
<!--        <img src="https://img01.yzcdn.cn/vant/share-sheet-link.png" class="van-share-sheet__icon">-->
<!--        <span class="van-share-sheet__name">复制链接</span>-->
<!--        <span class="van-share-sheet__option-description">描述信息</span>-->
<!--      </div>-->
<!--      <div role="button" tabindex="0" class="van-share-sheet__option"><img-->
<!--          src="https://img01.yzcdn.cn/vant/share-sheet-poster.png" class="van-share-sheet__icon"><span-->
<!--          class="van-share-sheet__name">分享海报</span></div>-->
<!--      <div role="button" tabindex="0" class="van-share-sheet__option"><img-->
<!--          src="https://img01.yzcdn.cn/vant/share-sheet-wechat.png" class="van-share-sheet__icon"><span-->
<!--          class="van-share-sheet__name">二维码</span></div>-->
    </div>

    <div style="margin: 16px;">
      <van-button block type="danger" native-type="submit" @click="onDelete">删除用户</van-button>
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  CellGroup,
  Toast,
  Divider,
  Button,
  Field,
  Uploader,
  NavBar,
  Notify,
  ShareSheet,
  Popup,
  Image as VanImage, Cascader, Dialog
} from 'vant';
import * as userAdminService from "@/api/admin/user";
import $store from "@/store";
import * as getDataService from "@/api/serverData/getServerData";
import {mapState} from "vuex";


export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Notify.name]: Notify,
    [Divider.name]: Divider,
    [Button.name]: Button,
    [Field.name]: Field,
    [Uploader.name]: Uploader,
    [NavBar.name]: NavBar,
    [ShareSheet.name]: ShareSheet,
    [Popup.name]: Popup,
    [VanImage.name]: VanImage,
    [Cascader.name]: Cascader,
  },
  data() {
    return {
      fileList: [
        {url: 'https://img01.yzcdn.cn/vant/leaf.jpg'},
      ],
      editItem: {},
      analysis: {},
      userInfoEditPopupShow: false,
      accountUser: {},
      pattern: /\d{6}/,
      uploader: [{url: 'https://img01.yzcdn.cn/vant/leaf.jpg'}],

      selectAddressPopupShow: false,
      cascaderValue: '',
      options: [
        {
          text: '浙江省',
          value: '330000',
          children: [{text: '杭州市', value: '330100'}],
        },
        {
          text: '江苏省',
          value: '320000',
          children: [{text: '南京市', value: '320100'}],
        },
      ],
      fieldNames: {
        text: 'n',
        value: 'v',
        children: 'c',
      },
    };
  },
  computed: {
    ...mapState({
      mapInfo: state => state.map.mapInfo,
    }),
    coordinate: function () {
      if ($store.state.map.mapInfo.lat !== undefined) {
        return $store.state.map.mapInfo.lat+ ',' + $store.state.map.mapInfo.lng
      }
      return null
    },
  },
  watch : {
    coordinate: function(val) {
      this.accountUser.coordinate = val;
    },
  },
  created() {
    this.getData()
    getDataService.getCity().then(res => {
      if (res.status === 200) {
        this.options = res.data
      }
    });
  },
  methods: {
    getData() {
      if (this.$route.params && this.$route.params.userId) {
        userAdminService.getAccountUser(this.$route.params.userId).then(res => {
          if (res.status === 200) {
            this.accountUser = res.data
            this.fileList = [{url: res.data.avatar}]
          }
        })

        userAdminService.getAnalysis(this.$route.params.userId).then(res => {
          if (res.status === 200) {
            this.analysis = res.data
            if (this.analysis.amountOfDay === null) {
              this.analysis.amountOfDay = 0
            }
            if (this.analysis.amountOfWeek === null) {
              this.analysis.amountOfWeek = 0
            }
            if (this.analysis.amountOfMonth === null) {
              this.analysis.amountOfMonth = 0
            }
            if (this.analysis.amountOfYear === null) {
              this.analysis.amountOfYear = 0
            }
            if (this.analysis.amount === null) {
              this.analysis.amount = 0
            }
            console.log(res.data)
          }
        })
      }
    },
    editUserCoordinate() {
      this.$router.push({path: '/map'})
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    toUserPrice() {
      this.$router.push({path: '/user/price/' + this.$route.params.userId})
    },
    toUserOrder(queryDateType) {
      if (queryDateType) {
        this.$router.push({path: '/user/order/' + this.$route.params.userId, query: {queryDateType: queryDateType}})
      } else {
        this.$router.push({path: '/user/order/' + this.$route.params.userId})
      }
    },
    editUserAddress() {
      this.selectAddressPopupShow = true
    },
    editUserInfo(key, username) {
      this.userInfoEditPopupShow = true
      this.editItem.key = key
      this.editItem.value = username
    },
    onSubmit() {
      if (this.editItem.value === undefined) {
        Toast("修改值不能为空")
        return
      }
      let editUser = {}
      editUser.id = this.accountUser.id
      if (this.editItem.key === '商户名') {
        editUser.nickName = this.editItem.value
        editUser.username = this.editItem.value
      } else if (this.editItem.key === '手机号码') {
        editUser.phone = this.editItem.value
      } else if (this.editItem.key === '详细地址') {
        editUser.detailedAddress = this.editItem.value
      }
      userAdminService.editUser(editUser).then(res => {
        this.userInfoEditPopupShow = false
        this.editItem = {}
        Notify({type: 'success', message: '新增成功'});
        this.getData()
      })
    },
    onDelete() {
      Dialog.confirm({
        title: '确认修改此用户吗？',
        message: '请三思三思～',
      })
        .then(() => {
          userAdminService.deleteUser( this.accountUser.id).then(res => {
            Notify({type: 'success', message: '删除成功'});
            this.$router.push({path: '/user/list'});
          })
          // on confirm
        })
        .catch(() => {
          // on cancel
        });
    },
    onAddressSubmit() {
      console.log(this.cascaderValue)
      if (this.accountUser.address === undefined) {
        Toast("修改值不能为空")
        return
      }
      let editUser = {}
      editUser.id = this.accountUser.id
      editUser.address = this.accountUser.address
      userAdminService.editUser(editUser).then(res => {
        this.editItem = {}
        Notify({type: 'success', message: '修改成功'});
        this.getData()
        this.selectAddressPopupShow = false
        this.userInfoEditPopupShow = false
      })
    },
    onCoordinateSubmit() {
      if (this.coordinate === undefined || this.coordinate === null || this.coordinate === '') {
        Toast("修改坐标不能为空")
        return
      }
      let editUser = {}
      editUser.id = this.accountUser.id
      editUser.coordinate = this.coordinate
      userAdminService.editUser(editUser).then(res => {
        this.editItem = {}
        Notify({type: 'success', message: '修改成功'});
        $store.commit('SET_MAP_INFO', {})
        this.getData()
      })
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({selectedOptions}) {
      this.selectAddressPopupShow = false;
      this.accountUser.address = selectedOptions.map((option) => option.n).join(' ');
    },
    onAreaChange({selectedOptions}) {
      this.accountUser.address = selectedOptions.map((option) => option.n).join(' ');
    }
  },
};
</script>

<style lang="less">
.user {
  &-poster {
    width: 100%;
    height: 53vw;
    display: block;
  }

  &-group {
    margin-bottom: 15px;
  }

  &-links {
    padding: 15px 0;
    font-size: 12px;
    text-align: center;
    background-color: #fff;

    .van-icon {
      display: block;
      font-size: 24px;
    }
  }
}
</style>
